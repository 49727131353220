import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Col, Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const NikkeGuidesRerollPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Reroll</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_4.png"
            alt="Reroll guide"
          />
        </div>
        <div className="page-details">
          <h1>Reroll</h1>
          <h2>A reroll guide for Goddess of Victory: NIKKE.</h2>
          <p>
            Last updated: <strong>02/01/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Video version" />
        <p>For those that prefer watching the guide in video version:</p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="4HHDRV1rGlU" />
          </Col>
        </Row>
        <SectionHeader title="Reroll methods" />
        <p>
          Goddess of Victory: NIKKE has in-build Guest Account system, which
          means you aren't forced to create an account for each attempt at
          rerolling - the forced account creation became popular recently in
          some gacha games, but thankfully Nikke still allows you to just use
          Guest Accounts.
        </p>
        <p>
          Sadly, there is no account delete option, but here's where Salted
          email method comes in!
        </p>
        <h5>Salted emails</h5>
        <p>
          <strong>Important!</strong> Google email accounts are highly
          recommended for rerolling. This is because Gmail accounts can abuse
          the salt system, which basically allows you to use the same e-mail
          multiple times for registration.
        </p>
        <p>
          To use a salted email, you add a +anything between the name and the
          @gmail portion of your e-mail address. For example, if your gmail
          account name is myname@gmail.com, a salted e-mail would be
          myname+anything@gmail.com.
        </p>
        <p>
          The string behind the + symbol can be any letters or numbers of any
          length. Still, to easily keep track of rerolled accounts, we recommend
          using numbers. Any e-mails sent to a salted e-mail will be redirected
          to the email without the salt used.
        </p>
        <p>
          If you're super serious about rerolling, we suggest to create a sheet
          or a txt file where you will track what salted account got what pulls.
        </p>
        <hr />
        <StaticImage
          src="../../../images/nikke/generic/reroll_1.png"
          alt="Reroll guide"
        />
        <p>When you first install the game, press the Create Account button.</p>
        <StaticImage
          src="../../../images/nikke/generic/reroll_2.png"
          alt="Reroll guide"
        />
        <p>
          Then, enter your email and password (to clarify: not the password you
          use for your email, but one you want to use for the game), but at the
          end of the email add +1. This will be your first rerolled account and
          let's hope the number never goes into 3 digits and the RNG will be on
          your side.
        </p>
        <StaticImage
          src="../../../images/nikke/generic/reroll_5.png"
          alt="Reroll guide"
        />
        <p>Here's how the salted email should look like on your second run.</p>
        <StaticImage
          src="../../../images/nikke/generic/reroll_3.png"
          alt="Reroll guide"
        />
        <p>
          Once you're done with the reroll (the process itself is described
          below), navigate the Menu screen and press the Account button.
        </p>
        <StaticImage
          src="../../../images/nikke/generic/reroll_4.png"
          alt="Reroll guide"
        />
        <p>
          The next screen will have a Log Out button that will take you back to
          the lobby and you will be able to create another account - just change
          the number at the end of the email.
        </p>
        <p>
          With this method, not only you're keeping all the accounts you've
          rolled and can come back to them at any time (when you for example
          have enough of rolling), but also it's the best method for those with
          limited internet transfer, as it means you just have to download the
          game once.
        </p>
        <h5>File manager abuse</h5>
        <p>
          Sadly after the recent ban on emulators, the File manager abuse no
          longer works.
        </p>
        <SectionHeader title="Reroll process" />
        <p>
          <strong>One reroll in NIKKE takes around 10-12 minutes</strong> so it
          can be said it's average compared to other games. Sadly, you are
          forced to play through the tutorial without the ability to skip any
          parts - also auto-skill and auto-aim features unlock after you finish
          the tutorial and gain freedom of movement, forcing you to manually
          play the few battles in Chapter 1 every time.
        </p>
        <p>
          Basically, just do what the game tells you to do till you reach the
          point around the 10 minute mark when you finish Chapter 1 and the game
          will ask you to do your first 10-pull.{' '}
          <strong>The tutorial 10-pull guarantees</strong> that you will receive
          one SSR rarity character (You can only get one SSR on tutorial pull.
          No more, no less.).
        </p>
        <p>
          What's great is that the tutorial pool isn't limited and{' '}
          <strong>you can obtain ANY SSR Rarity character</strong> from the
          three main factions - only Pilgrims and event-limited characters can't
          be pulled.
        </p>
        <p>
          Once you do the tutorial pull, it's time to gather whatever rewards
          are currently available in your mail if you haven't already - sadly,
          compared to the early days, most likely you won't be able to get
          enough Gems to do another 10-pull. You will have to play a bit more
          and reach and clear Stage 2-1 in Chapter 2 to get enough Gems to do
          another 10-pull (and this is where you can get a Pilgrim Nikke). For
          reference, you need 3000 gems for a 10-pull in Nikke.
        </p>
        <p>
          Hence, the reroll process will be to keep an account till you do 2
          10-pulls (total 20 pulls), in which the first is the tutorial 10-pull,
          and the second 10-pull is after you beat Stage 2-1 in Chapter 2, where
          we aim for a Pilgrim nikke.
        </p>
        <p>
          Our aim is to get 1-2 Meta Nikkes till this point (Ideally Red Hood or
          Modernia). One Meta Nikke is enough. If you don't get one of the Meta
          Nikkes, you repeat the process with a new salted account till you get
          one. Once you get the nikke you want, you are now done with rerolling!
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Extended Reroll" />
        <p>
          These rerolls are focused on improving the quality of each reroll, but
          require more time to execute than the process described above (usually
          30 minutes per reroll). We will be trying to maximise the chance of
          getting a Meta Pilgrim specifically, since all of our pulls will have
          a chance of dropping a Pilgrim.
        </p>
        <p>
          We will repeat the same reroll process as described in the previous
          section, but our pull management will be different. After finishing
          Stage 2-1, the tutorial ends. Now, we will go to the recruitment area
          and{' '}
          <strong>completely SKIP and NOT PULL ON the tutorial banner</strong>.
          This is important for several reasons. The tutorial 10-pull can only
          gives 1 SSR, and has no chance of dropping a Pilgrim. Instead, we will
          be unlocking Ordinary Banner (process on how to unlock this Banner is
          in the next paragraph) and spend our 10-pulls there, since on this
          banner we can get possibly get multiple SSR AND Pilgrims! Both
          Tutorial Banner and Ordinary Banner use the same resources! So don't
          get tricked into pulling on Tutorial Banner!
        </p>
        <p>
          To continue, after finishing Stage 2-1, we will go to the recruitment
          tab and use 3000 gems we have accumulated to 10-pull on the Special
          Recruit Banner! This is an exploit which will allow you to unlock the
          Ordinary Recruit banner and spend the Tickets you were supposed to use
          on the tutorial 10-pull on the Ordinary Banner!{' '}
          <i>Thanks to discord user tessa7883 for sharing this with us!</i>
        </p>
        <p>
          Now, after doing all of this comes the extension of the old reroll
          process. We urge you to push campaign and finish chapter 2, and also
          enter Active CD-Keys that are present. You can find a list of active
          CD-Keys and how to redeem cdkeys at the end of{' '}
          <a
            href="https://twitter.com/NIKKE_en/status/1588282670055759872?t=Hst5pTbWKZAplKQRtLGznw&s=19"
            target="_blank"
            rel="noreferrer"
          >
            this
          </a>{' '}
          guide.
        </p>
        <p>
          This will give you a bunch of extra gems, roughly 10-15 more pulls,
          and will take roughly 15 minutes to execute. The gems you now have can
          be spent on Special Recruitment Banner or Ordinary Recruitment Banner,
          whichever one you like! The chances of getting a pilgrim are equal on
          both banners. You can check the rates of getting each Nikke in the
          info box they provide.
        </p>
        <p>
          This in total will give you 35-40 pulls per reroll in 30-35 minutes,
          and will greatly improve the chance of you getting your favourite
          nikke, especially pilgrims!{' '}
        </p>
        <SectionHeader title="Who to aim for?" />
        <p>
          We know how the reroll process works in NIKKE, and the only question
          remains is who should we reroll for. If you want to make your own
          choice, check our tier list - click on any character you fancy and
          check her review.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Tier list"
            link="/nikke/tier-list"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_tierlist.png"
                alt="Tier List"
              />
            }
          />
        </Row>
        <p>Now let's move to reroll goals.</p>
        <p>
          Nikke teams at minimum require 2 Burst 3 units, 1 Burst 2 Unit, and 1
          Burst 1 unit as a minimum. This anniversary you possibly have a chance
          to get at least one meta unit of each category, but beware: 2 or 3 of
          the recommendations will be Pilgrims, who are extremely hard to get!
        </p>
        <h6>Burst 3 Targets</h6>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="rapi-red-hood" enablePopover />
          <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
          <NikkeCharacter mode="icon" slug="cinderella" enablePopover />
          <NikkeCharacter mode="icon" slug="modernia" enablePopover />
          <NikkeCharacter
            mode="icon"
            slug="scarlet-black-shadow"
            enablePopover
          />
        </div>
        <p>
          These are your reroll aims. All 5 of these Nikkes are immensely Meta
          units that you should reroll for! Rapi: The Hood is currently on rate
          up so if you do not want to spend hours rerolling, this is a good
          opportunity to snipe her down. However, if you can invest time into
          rerolling, we recommend aiming for one of Red Hood, Scarlet: Black
          Shadow, Cinderella, or Modernia from a reroll, and then spend the rest
          of your time pulling on Rapi's rate up banner so that you may get her
          as well! This should give you an extremely easy start to campaign.
        </p>
        <h6>Burst 2 Targets</h6>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="grave" enablePopover />
          <NikkeCharacter mode="icon" slug="crown" enablePopover />
        </div>
        <p>
          These 2 are currently the strongest B2 possible, with Crown has an
          edge over Grave. Nevertheless, rerolling for them is worth.
        </p>
        <h6>Burst 1 Targets</h6>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="liter" enablePopover />
        </div>
        <p>
          Nikke has released a limited time bonus recruit system in ordinary
          banner, where you may spend 100 pulls and get a confirmed meta SSR
          pick between{' '}
          <NikkeCharacter mode="inline" slug="naga" enablePopover />,{' '}
          <NikkeCharacter mode="inline" slug="alice" enablePopover /> and{' '}
          <NikkeCharacter mode="inline" slug="liter" enablePopover />. With the
          amount of free pulls at the start and the extended reroll method, new
          players should have free access to 100 pulls on Ordinary Banner. This
          should allow them to get Liter, who is commonly known as the BEST B1
          UNIT IN THE GAME!
        </p>
        <h6>Support alternatives</h6>
        <p>Some other good alternatives in the support role are as follows:</p>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="rouge" enablePopover />
          <NikkeCharacter mode="icon" slug="blanc" enablePopover />
          <NikkeCharacter mode="icon" slug="d-killer-wife" enablePopover />
          <NikkeCharacter mode="icon" slug="dorothy" enablePopover />
          <NikkeCharacter mode="icon" slug="tia" enablePopover />
          <NikkeCharacter mode="icon" slug="naga" enablePopover />
        </div>
        <p>
          Among these characters, only{' '}
          <NikkeCharacter mode="inline" slug="dorothy" enablePopover /> is a
          Pilgrim - the rest can be obtained from the tutorial pull as well!
          Also, if you decide to reroll for{' '}
          <NikkeCharacter mode="inline" slug="blanc" enablePopover /> &{' '}
          <NikkeCharacter mode="inline" slug="rouge" enablePopover /> or{' '}
          <NikkeCharacter mode="inline" slug="tia" enablePopover /> &{' '}
          <NikkeCharacter mode="inline" slug="naga" enablePopover />, please
          keep in mind that those units have a great synergy with each other.
          You would want to get both Tia AND Naga (or Blanc AND Rouge) to take
          full advantage of them. Having one of them is not enough.
        </p>
        <p>
          Some units may not be super meta, but they can help progression at the
          very least. They don't need to be paired with anyone and can work
          alone. Remember that if you are going for ideal rerolls, these are not
          the correct units. These are just the alternatives Here are the
          options for when you are tired of rerolling and can't be bothered to
          reroll more:
        </p>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
          <NikkeCharacter mode="icon" slug="ein" enablePopover />
          <NikkeCharacter
            mode="icon"
            slug="privaty-unkind-maid"
            enablePopover
          />
          <NikkeCharacter mode="icon" slug="snow-white" enablePopover />
          <NikkeCharacter mode="icon" slug="volume" enablePopover />
          <NikkeCharacter mode="icon" slug="dolla" enablePopover />
          <NikkeCharacter
            mode="icon"
            slug="quency-escape-queen"
            enablePopover
          />
        </div>
        <h6>Summary</h6>
        <p>
          Overall, if you just want to aim for one Nikke, we recommend aiming
          for one of the Burst 3s. But if you are a passionate perfectionist,
          aiming for 4 meta units on one reroll is as easy as it has ever been
          in Nikke's history! We hope you have a good rerolling session!
        </p>
        {/* <SectionHeader title="Who to aim for?" />
        <p>
          We know how the reroll process works in NIKKE, and the only question
          remains is who should we reroll for.
        </p>
        <p>
          If you want to make your own choice, check our tier list - click on
          any character you fancy and check her review.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Tier list"
            link="/nikke/tier-list"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_tierlist.png"
                alt="Tier List"
              />
            }
          />
        </Row>
        <p>Now let's move to reroll goals - and there the main ones:</p>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
          <NikkeCharacter mode="icon" slug="modernia" enablePopover />
          <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
          <NikkeCharacter
            mode="icon"
            slug="scarlet-black-shadow"
            enablePopover
          />
        </div>
        <p>
          Those characters above are the best damage dealers in the game that
          shine in nearly all content.{' '}
          <strong>
            Just be aware that they belong to the Pilgrim faction, so it's not
            possible to pull them from the tutorial banner.
          </strong>
        </p>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="crown" enablePopover />
        </div>
        <p>
          <strong>Crown</strong> - As for the most effective support in the
          game, the title will go to Crown. Ever since her release in May 2024,
          she has been used everywhere, from Campaign to Raids (except PvP). Not
          only does she provide survivability bonus via taunting + immunity, she
          also greatly enhances the team's DMG and accelerates their reload.
          Crown, however, also cannot be obtained from the Tutorial Pull since
          she is a Pilgrim.
        </p>
        <p>
          Still, in case that you want to reroll for those characters - and they
          can't be obtained in the tutorial pull, you need to turn your eyes
          toward support characters. And the best one is Liter:
        </p>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="liter" enablePopover />
        </div>
        <p>
          <strong>Liter</strong> is the best supporter in the game that provides
          ATK buff for the whole team and has an unique ability to repair the
          covers behind which your characters hide. She also provides Burst
          Skill cooldown reduction, one of the rarest abilities in the game. And
          she's not a Pilgrim, which allows you to pull her in tutorial banner!
        </p>
        <p>Good alternatives in the supporter role:</p>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="noir" enablePopover />
          <NikkeCharacter mode="icon" slug="blanc" enablePopover />
          <NikkeCharacter mode="icon" slug="d-killer-wife" enablePopover />
          <NikkeCharacter mode="icon" slug="dorothy" enablePopover />
          <NikkeCharacter mode="icon" slug="tia" enablePopover />
          <NikkeCharacter mode="icon" slug="naga" enablePopover />
        </div>
        <p>
          Among these characters, only Dorothy is a Pilgrim - the rest can be
          obtained from the tutorial pull. Also, if you decide to reroll for
          Noir/Blanc or Tia/Naga, please keep in mind that those units have a
          great synergy with each other. You would want to get both Tia AND Naga
          (or Blanc AND Noir). Having one of them is not enough.
        </p>
        <p>
          Some units may not be super meta, but they can help progression at the
          very least. They don't need to be paired with anyone and can work
          alone. Remember that if you are going for ideal rerolls, these are not
          the correct units. These are just the alternatives Here are the
          options for when you are tired of rerolling and can't be bothered to
          reroll more:
        </p>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
          <NikkeCharacter mode="icon" slug="naga" enablePopover />
          <NikkeCharacter mode="icon" slug="alice" enablePopover />
          <NikkeCharacter mode="icon" slug="noir" enablePopover />
          <NikkeCharacter
            mode="icon"
            slug="privaty-unkind-maid"
            enablePopover
          />
          <NikkeCharacter mode="icon" slug="harran" enablePopover />
          <NikkeCharacter mode="icon" slug="snow-white" enablePopover />
          <NikkeCharacter mode="icon" slug="volume" enablePopover />
          <NikkeCharacter mode="icon" slug="dolla" enablePopover />
        </div>
        <p>
          Overall{' '}
          <strong>
            getting a great Burst I or II support and a great Burst III damage
            dealers will make your early game much easier
          </strong>
          , but it might take you some time to reroll to get the ideal pairing
          you would like.
        </p> */}
        <SectionHeader title="Permanently active CD-Keys" />
        <p>These codes can only be redeemed once per account.</p>
        <ul>
          <li>NIKKE2023 - Gem x100</li>
          <li>NIKKE777 - Gem x100</li>
          <li>NIKKEFOR2024 - Gems x100</li>
          <li>NIKKEPC - Gem x100</li>
          <li>NIKKEFOR2023 - Gem x100</li>
        </ul>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297010"></div>
    </DashboardLayout>
  );
};

export default NikkeGuidesRerollPage;

export const Head: React.FC = () => (
  <Seo
    title="Reroll | NIKKE | Prydwen Institute"
    description="How to reroll in Goddess of Victory: NIKKE."
    game="nikke"
  />
);
